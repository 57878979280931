import React,{Component} from 'react';
import {Carousel,CarouselItem} from 'react-bootstrap';
import posed from 'react-pose';
import {parseHtml} from "../utils/utils.js";
import {Player,ControlBar,ReplayControl,VolumeMenuButton} from 'video-react'
import "./projet.scss"

const Viewer=posed.div(
    {
        initialPose:{opacity:0},
        hidden:{opacity:0},
        visible:{opacity:1}
    }
)

class Projet extends Component{
    constructor(props){
        super(props);
        this.state = {
            index: 0,
            contentIndex:0,
            direction: null,
          };
        //this.handleClickClose=this.handleClickClose.bind(this);
        this.handleSelect=this.handleSelect.bind(this)
    }
    handleSelect(selectedIndex, e) {
        this.setState({
          index: selectedIndex,
          direction: e.direction,
          contentIndex:this.calculateIndex()
        });
      }
    calculateIndex(){
        if(this.refs['project-player'])
            this.refs['project-player'].pause();
        const {content}=this.props;
        const {contentIndex}=this.state
        let finalIndex;
        
        if(contentIndex>=(content.content.length-1)){
            finalIndex=0;
        }else{
            finalIndex=contentIndex+1
        }
       
        return finalIndex
    }
    displaySteps(gallery){
       return( 
            gallery.map((item,id)=>{
            console.log(id)
            return(
                <CarouselItem className='item-conteneur' key={id}>
                    {this.displayMedia(item,id)}
                </CarouselItem>
                )
            }
            )
        )
    }
    displayMedia(item,id){
        let tag;
        switch(item.type){
            case 'image':
                 tag=<img className='projet-img' alt={`project-img-${id}`} src={item.url} />
                break;
            case 'video' :
                console.log("video-->",item.url)
                tag=<div>
                        <Player ref="project-player" className='video-fluid video-project' playInline src={item.url}>
                        <ControlBar>
                            <ReplayControl seconds={10} order={1.1} />
                            <VolumeMenuButton disabled />
                        </ControlBar>
                        </Player>
                    </div>
                break;
            default:
        }
        return tag;
    }
 
    displayItem(item){
        return(
            <Viewer pose={true} className='projet-p'dangerouslySetInnerHTML={parseHtml(item)} />
        )
    }
    render(){
        const {content,cat,init}=this.props
        const { index, direction, contentIndex } = this.state;
        console.log(content);
        return(
            <Viewer pose={init ?'visible':'hidden'}>
                <div className={`exit c${cat}`} onClick={this.props.closeAction}>X</div>
                <Carousel 
                    className={`project-viewer-${cat} slide carousel-fade`}
                    activeIndex={index}
                    direction={direction}
                    onSelect={this.handleSelect}
                    interval={false}         
                >
                   {this.displaySteps(content.gallery)}                 
                </Carousel>
                <h3 className={`title-project h3-${cat}`}>{content.title}</h3>
                {this.displayItem(content.content[contentIndex])}
            </Viewer>
        )
    }
}
export default Projet;