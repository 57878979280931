import React, {Component} from "react";
import {Container, Row, Col,Form,InputGroup,Button, Figure} from "react-bootstrap";
import Title from "./animated/Title"
import "./reas.scss";
import "./propos.scss";
import {parseHtml} from"../utils/utils.js"
import Incipit from "./animated/Incipit";
import FigureImage from "react-bootstrap/FigureImage";
import FigureCaption from "react-bootstrap/FigureCaption";




class Propos extends Component {
    constructor(...props){
        super(...props);
        this[this.props.id]=React.createRef();
        this.state = { 
        };
        
    }
    render(){
        const {titre,incipit,content,id}=this.props;
        const toAnimate=this.props.id===this.props.idPartEncours
        const className=this.props.id===this.props.idPartEncours ?'h2-main h2-in title-reas' : 'h2-main h2-out title-reas'; 
        console.log("content",content[0],incipit);
        return(
            
            <Container id={id}fluid="true" ref={this[id]} className="blue-container">
                <Row fluid="true" className="reas-header">
                    <Title className={className} pose={toAnimate} content={titre}/>
                </Row>
                <Row fluid="true" className="row-propos">
                    <Col sd={4} md={4}>
                       
                            <Figure className="img-r">
                                <FigureImage
                                    alt="David Lechermeier"
                                    src={content[0].asset.url}
                                    className="img-fond-r"
                                />
                                <FigureCaption className="caption-photo">
                                David Lechermeier<br/><span>Président-fondateur de Dé-mots.</span>
                                </FigureCaption>
                            </Figure>
                         
                    </Col>
                    <Col sd={12} md={8} className="cover-bio">
                        <Incipit className="dumb" pose={toAnimate}>
                        {content[0].content.map((item,id)=>{
                                console.log(item)
                            return(
                                <p key={id} dangerouslySetInnerHTML={parseHtml(item)} />
                            )
                        }
                        )}
                        </Incipit>
                    </Col>
                 </Row>
            </Container>
           
        )
    }
}

export default Propos;