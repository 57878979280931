import React,{Component} from 'react';
import { Carousel, Container, CarouselItem } from 'react-bootstrap';
import VideoCover from 'react-video-cover';
import posed from "react-pose";
import Title from './animated/Title'
import "./manifeste.scss";
import { Player } from 'video-react';
import {detectIE} from '../utils/utils.js'


const TIME_CAROUSSEL=8000;

const Claim=posed.p(
    {
        open:{
            opacity:1,
            x:0
        },
        closed:{
            opacity:0,
            x:300,
        },
        transition: { duration: 2000}
    }
)

const Cont=posed.div(
    {
        open:{
            y:0,
            opacity:1,
            beforeChildren:true,
            delayChildren: 300,
            staggerChildren: 600,
        },
        closed:{
            y:-30,
            opacity:0,
            afterChildren:true
        },
        transition: { duration: 2000, delay:1000}
    }
)

class Manifeste extends Component{
    constructor(props){
        super(props)
        //this["ref_"+this.props.id]=React.createRef();
        this[this.props.id]=React.createRef();
        this.interval=null;
        this.state={
            onVisible:false,
            activeIndex:0,
            toAnimate:false
        }
        this.updateActiveIndex=this.updateActiveIndex.bind(this);
        this.handleSelect=this.handleSelect.bind(this);
    }
    componentWillReceiveProps(newProps){
        //console.log(this.props.id,"// ",newProps.idPartEncours," ///",this.state.onVisible)
        if(newProps.idPartEncours===this.props.id&&!this.state.onVisible){
            this.setState({onVisible:true,toAnimate:true},this.launchCarousselAnim())
           
        }else if(this.state.onVisible&&this.props.id!==newProps.idPartEncours){
            console.log("je dois stopper l'anim")
            this.setState({onVisible:false,toAnimate:false},this.clearCarousselAnim())
        }else{
        }

    }
    componentDidMount(){
        const isVisible=(this.props.id===this.props.idPartEncours) ? true : false
        this.setState({toAnimate:isVisible})
        //console.log("in manifeste ",isVisible)
    }
    componentDidUpdate(){
        //console.log("update")
   
    }
    launchCarousselAnim(){
        //console.log("LAUNCH ANIM",this.state.onVisible);
        this.interval=setInterval(this.updateActiveIndex,TIME_CAROUSSEL);
    }
    updateActiveIndex(){
       // console.log("UPDATE INDEX");
        let active;
        if(this.state.activeIndex>=this.props.content.length-1){
            active=0;
        }else{
            active=this.state.activeIndex+1
        }
        this.setState({activeIndex:active})
    }
    clearCarousselAnim(){
        console.log("STOP ANIM ", this.state.onVisible)
        clearInterval(this.interval)
        this.interval=null;
    }
    parseHtml(pContent){
        return {__html:pContent}
    }
    handleSelect(){
        this.updateActiveIndex();
    }
    displayItems(pContent,pVisible){
        let index=0;
 
        return(
            pContent.map(item=>{
            
                const options={
                    src:item.asset.url,
                    autoPlay:(this.state.onVisible&&index===this.state.activeIndex) ? true : false,
                    loop:true
                }
                //console.log("OPTIONS ",item.id," ")
                return(
                    <CarouselItem key={item.id}>
                        <div className='video-cover'>
                           {!detectIE()?
                           <VideoCover videoOptions={options} playinline className='video-item'/>
                            :
                           <Player className='video-IE' src={item.asset.url} autoPlay={true} loop={true} playinline controls={false}/>
                           }
                        </div>
                        <div className="over-blend"></div>       
                        <Cont className='caption-carousel' pose={pVisible ? 'open':'closed'}>
                            {item.content.map((part,id)=>{
                                //console.log(part);
                                return(
                                    <p className='item-caption' key={id} dangerouslySetInnerHTML={this.parseHtml(part)}/>
                                )
                            })
                            }
                        </Cont>
                    </CarouselItem>
                )
            })
        )
    }
    render(){
        const {titre,content,id}=this.props
        const {toAnimate}=this.state
        const decoration=(this.props.id===this.props.idPartEncours) ? " h2-manifeste h2-in" : "h2-manifeste h2-out"
        //console.log("RENDER --> ",toAnimate)
        return(
            <Container ref={this[id]} fluid='true' className="header-manifeste">
             
                <Title
                    pose={toAnimate}
                    className={decoration}
                    content={titre}
                    alpha={true}
                />
                
                <Carousel className="header-carousel slide carousel-fade"
                    controls={false}
                    onSelect={this.handleSelect}
                    activeIndex={this.state.activeIndex}
                    wrap={true}
                    pauseOnHover={true}
                    interval={false}
                >
                   {this.displayItems(content,toAnimate)}
                   
                </Carousel>
            </Container>
          
        )
    }
}
export default Manifeste;