import posed from 'react-pose';
import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import './thumb.scss'


const OverDiv=posed.div(
    {
        initialPose:{opacity:0},
        hidden:{opacity:0},
        visible:{opacity:0.8}

    }
)
const ThumbP=posed.p(
    {
        initialPose:{opacity:0},
        hidden:{opacity:0},
        visible:{opacity:1}

    }
)

class Thumb extends Component{
    constructor(props){
        super(props)
        this.state={over:'hidden',clicked:false}
        this.onRolllHandler=this.onRolllHandler.bind(this)
        this.onClickHandle=this.onClickHandle.bind(this);
    }
    componentDidUpdate(){
        if(!this.props.toDisplay){
            if(this.state.clicked){
                console.log("need to hide")
                this.setState({clicked:false,over:'hidden'})
            }
        }
    }
    onRolllHandler(){
        if(!this.state.clicked)
            this.setState({over:this.state.over==='hidden'?'visible':'hidden'})
    }
    onClickHandle(){ 
        console.log("Click on",this.props.num)
        this.setState({clicked:true});
        this.props.onClick(this.props.num,this.props.cat);
    }
    render(){
        const{title,cover,id,client}=this.props
        const{over}=this.state;
      //  console.log("props -->",this.props.title, this.props.toDisplay, this.props.num)
        
        return(
        <Col ld={3} md={2} sd={2} className={`thumb-item-${id}`} onMouseOver={this.onRolllHandler} onMouseOut={this.onRolllHandler} onClick={this.onClickHandle}>
            <OverDiv className='cover' pose={over}/>
            <ThumbP pose={over} className='p-item'>{title}</ThumbP>
            <ThumbP pose={over} className="p-client">{client}</ThumbP>
            <img title={title} alt={title} src={cover}/>
        </Col>
        )
    }
}
export default Thumb;