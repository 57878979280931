import React, {Component} from "react"
import {Container, Row, Col, Image, Figure} from "react-bootstrap";
import Projet from "./projet"
import {parseWordpressContent,parseHtml} from"../utils/utils.js";
import Title from "./animated/Title";
import Incipit from "./animated/Incipit"
import Thumb from "./animated/Thumb"
import "./reas.scss";
import Rea from "../images/rea-gen.png";

class Realisations extends Component{
    constructor(props){
        super(props)
        this.state={
            content:{},
            displayed:false,
            diplayProject:false,
            project:0,
            category:0
        }
        this.navSelect=this.navSelect.bind(this)
        this.closeProject=this.closeProject.bind(this)
        this[props.id]=React.createRef();
    }
    componentWillMount(){
        this.setState({contents:this.props.content})
    }

    navSelect(pNum, pCat){
        console.log("select",pNum)
        //const idContent=event.currentTarget.dataset.id;
        this.setState({diplayProject:true,project:pNum,category:pCat})
        console.log(this.searchContent(pNum));
        //this.setState({content:this.searchContent(idContent)})
    }
    handleSelectFirst(e,cat,proj){
        e.stopPropagation();
        const projet=this.findProjects(proj,cat)[0].id;
        console.log("projet",cat,proj);
        this.navSelect(projet,cat)
    }
    searchContent(pId){
        const {content}=this.props;
        return content.find((item)=>{
            //console.log(item.id, pId)
            return item.id===Number(pId);
        })
    }
    iterateThruReaContent(pArrayOfContent){

    }
    displayContent(){
        const {content,displayed}=this.state
        if(displayed){
            return(
                <Col>
                    <h3>{content.title}</h3>
                    <Figure>
                        <Figure.Image
                            src={content.asset!==undefined ? content.asset.url : "https://www.gstatic.com/webp/gallery/1.jpg"}
                            height={120}
                        />
                        <Figure.Caption>
                            {content.content.map((item,id)=>{
                                //console.log(item)
                                return(
                                    <p key={id} dangerouslySetInnerHTML={parseHtml(item)}/>
                                )
                            })}
                        </Figure.Caption>
                    </Figure>
                    <h3>Quelques projets :</h3>
                </Col>
            )
        }
        else{
            return(
                <Image src={Rea}fluid />
            )
        }
    }
    findProjects(pProjets,pId){
        //console.log("projets",pProjets,pId)
        return pProjets.filter(projet=>projet.category===pId)
    }
    findItem(pProjets,pCat,pId){
        console.log(this.findProjects(pProjets,pCat))
        return this.findProjects(pProjets,pCat).filter(item=>item.id===pId)[0];
    }
    displayThumb(pProjets,pId,indice){
        const thumb=this.findProjects(pProjets,pId);
        const {diplayProject,project}=this.state;
        //console.log("thumb",thumb)
        return(
            <Row  fluid={"true"} className={`thumb-${indice}`}>
                {thumb.map((asset,id)=>{
                    const toDisplay=project===asset.id ? true : false;
                   //console.log("projet à afficher",project)
                   return (
                    <Thumb 
                        key={id}
                        title={asset.title}
                        cover={asset.cover}
                        id={indice}
                        num={asset.id}
                        client={asset.client}
                        toDisplay={toDisplay}
                        cat={pId}
                        onClick={this.navSelect}
                    />
                )})
                }
            </Row>
        )
    }
    closeProject(){
        console.log("Cest le this",this)
        this.setState({project:0,category:0})
    }
    render(){
        const {titre,incipit,id}=this.props
        const {content,projets}=this.props;
        const{category,project}=this.state;
        const toAnimate=this.props.id===this.props.idPartEncours
        const className=this.props.id===this.props.idPartEncours ?'h2-main h2-in title-reas' : 'h2-main h2-out title-reas'      
        return(
            <Container id={id}fluid="true" ref={this[this.props.id]} className='blue-container'>
                <Row fluid="true" className="reas-header">
                    <Title className={className} pose={toAnimate}content={titre}/>
                </Row>
                <Row fluid="true" className="reas-header">
                    <Incipit className="reas-incipit-cont" pose={toAnimate}>
                        <p className="reas-incipit" dangerouslySetInnerHTML={parseHtml(parseWordpressContent(incipit))} />
                     </Incipit>
                </Row>
                <Row>
                    {content.map((item,indice)=>{
                        //console.log("category",item.id)
                        const classFigure=(project!==0 && item.id===category) ?'rea-img alpha' : 'rea-img' 
                        return(
                            <Col md={12} key={item.id}>
                                {
                                     (item.id!==this.state.category)&&
                                        <div className={`rea-label l-${indice}`}>
                                            <p dangerouslySetInnerHTML={parseHtml(item.content[0])} />
                                        </div>
                                }
                                <Figure className="rea-figure">
                                    {(project!==0 && item.id===category)&&
                                        <Projet
                                            closeAction={this.closeProject}
                                            cat={indice}
                                            init={true}
                                            content={this.findItem(projets,category,project)}
                                        />
                                    }
                                    <Figure.Image
                                        src={item.asset.url}
                                        className={classFigure}
                                        onClick={event=>this.handleSelectFirst(event,item.id,projets)}
                                    />
                                    <Figure.Caption className={`rea-content`}>
                                        <h3 className={`c-${indice}`}>{item.title}</h3>
                                    </Figure.Caption>
                                    
                                </Figure>
                                <div className="rea-projet">
                                    <h4 className={`l-${indice}`}>
                                        Quelques exemples de projets
                                    </h4>
                                    {this.displayThumb(projets,item.id,indice)}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        )
    }
}

export default Realisations