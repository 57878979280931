import React, { Component } from 'react';
import './App.scss';
import {Container, Row,Col} from 'react-bootstrap';
import axios from 'axios';
import Header from './components/header';
import {parseWordpressContent} from './utils/utils';
import Manifeste from './components/manifeste';
import Approche from "./components/approche"
import Realisation from "./components/realisations"
import Contact from './components/contact';
import Clients from './components/clients';
import Propos from "./components/propos"
import {TweenLite,ScrollToPlugin} from 'gsap/all';
import logo from './images/logo.svg';

//import scrollTo from '../node_modules/gsap/ScrollToPlugin';

const URL_CAT="http://de-mots.com/wp/wp-json/wp/v2/categories";
const URL_PAGES="http://de-mots.com/wp/wp-json/wp/v2/pages/"
const URL_MENU="http://de-mots.com/wp/wp-json/sections/menu";
const URL_GEN="http://de-mots.com/wp/wp-json/?_query={name:name,title:description}";
const URL_CONTENT="http://de-mots.com/wp/wp-json/sections/v1/post";

const HEADER_BIG=200;
const HEADER_SMALL=100;

const OFFSET=15;
const scrollTo=ScrollToPlugin;

class App extends Component {
  constructor(props){
    super(props);
    this.state=
      {
        name:"",
        menu:[],
        loaded:false,
        headerHeight:HEADER_BIG,
        sectionsRef:[],
        sectionEnCours:0,
        projets:[]
      }
      this.handleScroll=this.handleScroll.bind(this)
      this.handleNav=this.handleNav.bind(this)
      this.appRef=React.createRef()
      this.tween=null;
  }
  componentWillMount(){
    let time=new Date().getTime();
    Promise.all([this.getMenu(URL_MENU),this.getCategories(URL_CAT),this.getPage(URL_PAGES),this.getContent(URL_CONTENT),this.getInfos(URL_GEN)]).then(values=>{
      console.log("VALUES",values);
        const menu =values[0];
        const cats=values[1];
        const pages=values[2];
        const content=values[3][0];
        const projets=values[3][1]
        for(let item of menu){
          const cat=cats.find(c=>{
            if(c.name===item.name.toLowerCase()){
              const elem=content.filter(ct=>{
               return ct.category===c.id;
              }
              ).sort((a,b)=>a.order-b.order);
              c.contents=elem;
            }
           return c.name===item.name.toLowerCase();
          })
          const page=pages.find(p=>{
            return p.url===item.url;
          })
          
         if(cat){
           item.page=page;
           item.page.contents=cat.contents;
           console.log("cat of contents",cat.contents)
           delete cat.contents;
           item.cat=cat;
          //console.log("cat",item);
           console.log("------");
           console.log(new Date().getTime()-time);
         }
        }
        //
        console.log("menu",menu);
        this.setState(
          {
            name:values[4],
            menu:menu,
            loaded:true,
            sectionEnCours:menu[0].id,
            projets:projets
          }
        );
        window.addEventListener('scroll', this.handleScroll, { passive: true })
       
        
    }
    )
    //hooks


  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  componentDidUpdate (){
    //console.log("LONGUEUR--> "+this.state.sectionsRef.length)
    if(this.state.loaded&&this.state.sectionsRef.length===0){
      this.setRefs(this.state.menu);
    }

  }
  setRefs(pMenuArray){
    const refs=[];
    for(let ref of pMenuArray){
      console.log("ref -->",this[ref.id])
      if(this[ref.id]===undefined)
        return
      refs.push({id:ref.id,ref:this[ref.id][ref.id].current})
    }
    this.setState({sectionsRef:refs})
  }
  handleNav(params){
    //console.log("params",params)
    const node=this[params][params].current;
    //console.log("node ", node.getBoundingClientRect().top);
    const toReach=this.getPosition(node)+OFFSET
    //console.log("value",toReach,this.appRef.current)
    //
    //console.log(scrollTo);
    TweenLite.to(window,0.5,{scrollTo:{y:toReach}});
   //window.scrollTo(0,(node.getBoundingClientRect().top+window.scrollY)-100);
  }
  handleScroll(event){
    this.checkSectionEnCours()
    const {headerHeight}=this.state
      if(window.scrollY>=(window.innerHeight/2)){
        this.setState({headerHeight:HEADER_SMALL})
      }else if(headerHeight===HEADER_SMALL){
       // console.log("je suis au dessus");
        this.setState({headerHeight:HEADER_BIG})
      }
     
  }
  getPosition(node){
    const rect=node.getBoundingClientRect();
    return rect.top+window.scrollY-this.state.headerHeight
  }
  checkSectionEnCours(){
    const{sectionsRef}=this.state;
   // console.log("-----START-------",sectionsRef.length);
    for(let ref of sectionsRef){

      const rect=ref.ref.getBoundingClientRect();
      //const elemPosy=rect.top+window.scrollY-(this.state.headerHeight);
      //const elemLimity=(rect.top+window.scrollY+rect.height)-this.state.headerHeight
      const breakPoint=window.innerHeight/2.2;
     // console.log("ELEMENT",ref)
      //console.log(window.scrollY, rect.top+window.scrollY, rect.height)
      //console.log("ScrollPosition : ",window.scrollY," :: ",rect.top+window.scrollY," :: ",rect.top+rect.height)
      //console.log(window.scrollY,window.innerHeight,window.document.body.clientHeight);
      //console.log(rect.top)
     // console.log(window.scrollY)
      //console.log(rect.top+rect.height)
      //console.log("--------------")
     // if((window.scrollY+(window.innerHeight/2))<=elemPosy&&(window.scrollY+(window.innerHeight/2))>=elemLimity){
       if(rect.top<=(breakPoint)&&rect.top+rect.height>=breakPoint&&window.scrollY>170){
       // console.log("JE SUIS DANS LA SECTION : ",ref.id);
        if(this.state.sectionEnCours!==ref.id){
          this.setState({sectionEnCours:ref.id})
          
        }
        return;
      }else if(window.scrollY<170){
        this.setState({sectionEnCours:sectionsRef[0].id})
      }
      
    }
  }
  getInfos(urlInfos){
    return axios.get(urlInfos).then(info=>{
      const infos={};
      infos.name=info.data.name;
      infos.tagLine=info.data.title;
      return infos;
    });
  }
  getPage(urlPage){
    return axios.get(urlPage).then(res=>{
      const pages=[];
      for(let p of res.data){
        const o={};
        o.id=p.id;
        o.name=p.title.rendered;
        if(p.excerpt)
         o.content=p.content.rendered;
        if(p.content)
           o.full=p.content.rendered;
        o.url=p.link;
        pages.push(o);
      }
      return pages;
    }
    )
  }
  getMenu(urlMenu){
   
    return axios.get(urlMenu).then(res=>{   
        const menu=[];
        for(let r of res.data){
          //console.log("MENU",r)
          const o={}
          o.id=r.ID;
          o.name=r.title;
          o.url=r.url;
          menu.push(o);
        }
        return menu;
      }
    )
   
  }
  getCategories(urlCat){
  
    return axios.get(urlCat).then(res=>{
      const categories=[];
      //console.log("CAT",res.data)
        for(let c of res.data){
          //console.log(c);
          const o={};
          o.id=c.id;
          o.name=c.name;
          categories.push(o);
        }
        return categories;
      }
    )
  
  }
  getPages(urlPages){
  
    return axios.get(urlPages).then(res=>{
      const pages=[];
        for(let p of res.data){
          //console.log(p);
          const o={};
          o.id=p.id;
          o.title=p.title.rendered;
          o.content=p.excerpt.rendered;
          o.link=p.link;
          pages.push(o);
        }
       
        return pages;
      }
    )
  }
  getContent(urlContent){
   
    return axios.get(urlContent).then(res=>{
      const content=[]
      const projets=[]
      //console.log("CONTENT",res.data)
        for (let p of res.data){
          const o={};
          //console.log(p)
          o.id=p.ID;
          o.title=p.post_title;
          if(p.acf.incipit!==undefined)
            o.incipit=p.acf.incipit;
          if(p.acf.picto!==false)
            o.asset=p.acf.picto;
          o.order=p.acf.order;
          if(p.acf.section!==undefined){
            if(p.acf.section===1){
              console.log(p.acf.section)
              if(p.acf.client!==undefined)
                o.client=p.acf.client
              o.content=[]
              o.content.push(p.acf.resume);
              o.content.push(p.acf.action);
              o.content.push(p.acf.key_points);
              o.category=p.acf.link;
              o.cover=p.acf.cover.url;
              if(p.acf.gallery)
                o.gallery=this.parseProjectGallery(p.acf.gallery)
              //
              projets.push(o);         
              //
            }else{
              o.category=p.acf.section[0];
              if(p.acf.tagline)
                o.content=p.acf.tagline;  
              else
               o.content=parseWordpressContent(p.post_content);
              if(p.acf.logos)
              o.gallery=this.parseProjectGallery(p.acf.logos)
              //console.log("TEST REGEX ",parseWordpressContent(o.content));
              //console.log("--------");
              content.push(o);
            }
          }
       
        }
        return [content,projets];
      }
    )
  }
  parseProjectGallery(pGallery){
    const gallery=[];
    for (let asset of pGallery){
      const a={}
      a.type=asset.type;
      a.url=asset.url;
      gallery.push(a);
    }
    return gallery;
  }
  _findElem(pToken,pArray){
   // console.log("-------------")
    return pArray.find(el=>{
      //console.log(el.name.replace(/ /g,''),pToken.replace(/ /g,''),el.name.replace(/ /g,'')===pToken.replace(/ /g,''))
      return el.name.replace(/ /g,'')===pToken.replace(/ /g,'');
    })
  }
  findTitle(pTitle){
    const{menu}=this.state;
    //console.log(this._findElem(pTitle,menu))
   return this._findElem(pTitle,menu).page.name;
  }
  findIncipit(pIncipit){
    const{menu}=this.state;
    return this._findElem(pIncipit,menu).page.content;
  }
  findFullPage(pPage){
    const{menu}=this.state;
    return this._findElem(pPage,menu).page.full;
  }
  findContent(pContent){
    const{menu}=this.state;
    return this._findElem(pContent,menu).page.contents;
  }
  findId(pId){
    const{menu}=this.state;
    return this._findElem(pId,menu).id
  }
  displayApp(pLoaded,...args){
    //console.log("args ",args)
    if(pLoaded){
      const manifeste=this.displayManifeste();
      const approche=this.displayApproche();
      const realisations=this.displayRea()
      const contact=this.displayContact();
      const clients=this.displayClients();
      const propos=this.displayPropos();
      const headerClass=(this.state.headerHeight===HEADER_BIG) ?"de-mots-header" : "de-mots-header-small"
     // console.log("inApp",headerClass)
      return this.mainApp(args[1].tagLine,args[0],manifeste, approche, realisations, propos, clients, contact, headerClass, this.handleNav)
    }
    else
      return <LoaderMessage />
  }
  displayManifeste(){
    return (
      <Manifeste 
        titre={this.findTitle("Manifeste")} 
        content={this.findContent("Manifeste")}
        id={this.findId("Manifeste")}
        ref={elem=>this[this.findId("Manifeste")]=elem}
        idPartEncours={this.state.sectionEnCours}
      />
    )
   
  }
  displayApproche(){
    return(
      <Approche
        titre={this.findTitle("Approche")}
        incipit={this.findIncipit("Approche")}
        content={this.findContent("Approche")}
        id={this.findId("Approche")}
        ref={elem=>this[this.findId("Approche")]=elem}
        idPartEncours={this.state.sectionEnCours}
      />
    )
  }
  displayRea(){
    return(
      <Realisation
        titre={this.findTitle("Réalisations")}
        incipit={this.findIncipit("Réalisations")}
        content={this.findContent("Réalisations")}
        projets={this.state.projets}
        id={this.findId("Réalisations")}
        ref={elem=>this[this.findId("Réalisations")]=elem}
        idPartEncours={this.state.sectionEnCours}
      />
    )

  }
  displayClients(){
    return(
      <Clients
        titre={this.findTitle("Clients")}
        incipit={this.findIncipit("Clients")}
        content={this.findContent("Clients")}
        id={this.findId("Clients")}
        ref={elem=>this[this.findId("Clients")]=elem}
        idPartEncours={this.state.sectionEnCours}
      />
    )
  }
  displayPropos(){
    return(
      <Propos
        titre={this.findTitle("À-propos")}
        incipit={this.findIncipit("À-propos")}
        content={this.findContent("À-propos")}
        id={this.findId("À-propos")}
        ref={elem=>this[this.findId("À-propos")]=elem}
        idPartEncours={this.state.sectionEnCours}
      />
    )
  }
  displayContact(){
    return(
      <Contact 
      titre={this.findTitle("Contact")}
      incipit={this.findIncipit("Contact")}
      content={this.findFullPage("Contact")}
      id={this.findId("Contact")}
      ref={elem=>this[this.findId("Contact")]=elem}
      idPartEncours={this.state.sectionEnCours}
      />
    )
  }
  displayFooter(){

  }
  mainApp(title,menu,manifeste,approche,reas, propos,clients,contact,style,navFunction){
    //console.log("props",props)
    //const {title,menu,manifeste,approche,reas,contact,style,navFunction}=props;
    const {sectionEnCours}=this.state;
    //console.log("header id en cours ",sectionEnCours);
    return(
      <Container fluid={true} ref={this.appRef}>
          <Header title={title} menu={menu} style={style} navFunction={navFunction} activePart={sectionEnCours}/>
            {manifeste}
            {approche}
            {reas}
            {propos}
            {clients}
            {contact}
            <Footer/>
      </Container>
      
    )
  }
  render() {
    //console.log("state",this.state.menu)
    const {loaded,menu,name,sectionsRef}=this.state;
    //if(loaded&&sectionsRef.length===0)
        //this.setRefs(this.state.menu);
    return (
      this.displayApp(loaded,menu, name)
    );
  }
}
const Footer=()=>{
  return(
    <Container fluid="true" className='footer'>
      <Row fluid='true' className='footer-row'>
          <Col xs={12} md={4}>
            <p>©De-mots, tous droits réservés | 2019</p>
          </Col>
          <Col xs={12} md={4}>
            <p>De-mots, 38 rue Servan, 75011 Paris</p>
          </Col>
          <Col xs={12} md={4}>
            <p><strong>contact: </strong><a href='mailto:contact@de-mots.com'>écrivez-nous !</a></p>
          </Col>
      </Row>
      <Row fluid='true' className='footer-row'>
        <Col>
        <p className="little">L’ensemble de ce site relève de la législation française et international sur le droit d’auteur et la propriété intellectuelle. Toute reproduction ou représentation intégrale ou partielle, du site ou de l’un des éléments qui le composent est expressément interdite, de même que leur altération.</p>
          </Col>
      </Row>
    </Container>
  )
}

function LoaderMessage(){
  return(
    <Row fluid="true" className="cont-loading">
       <img 
          src={logo}
          title="Logo"
          alt="Logo du site De-mots"
          className="logo-load"
        />
      <p>Chargement en cours...</p>
    </Row>
  )
}

export default App;
