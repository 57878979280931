import React, {Component} from "react";
import {Container, Row, Col,Form,InputGroup,Button} from "react-bootstrap";
import Title from "./animated/Title"
import "./reas.scss"
import {parseWordpressContent,parseHtml} from"../utils/utils.js"
import Incipit from "./animated/Incipit";
import * as emailjs from 'emailjs-com';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';


const SERVICE_ID="smtp_server";
const TEMPLATE_ID="contact_form";
const USER_ID="user_qAmibxyoDEMWdA23s1KNQ"


class Contact extends Component {
    constructor(...props){
        super(...props);
        this[this.props.id]=React.createRef();
        this.state = { 
            validated: false,
            name:"",
            email:"",
            subject:"",
            message:"",
            isSend:false,
            isError:false
        };
        
    }
    handleChange(event){
        this.setState({[event.target.name]:event.target.value});
    }
    handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
        
          console.log("DE LA GROSSE MERDE");
          this.setState({ validated: true });
        }else{
           console.log("FEU, j'ENVOIE !!!")
           this.sendData()
           form.reset();
        }
        
    }
    displayNotif(isSucess,message){
        const bg=(isSucess)?'rgba(67,225,175,0.9)':'rgba(255,20,0,0.9)';
        const position="bottom"
        toaster.notify(({ onClose }) => (
            <a href="#" style={{ textDecoration: "none" }} onClick={onClose}>
              <div
                style={{
                  overflow: "hidden",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor:bg,
                  width:'70vw',
                  borderRadius:"10px",
                  padding:"20px"
                }}
              >
                <p style={{color:"#FFF" ,textAlign:"center",display: "block", fontWeight:"bold"}}>
                {message}
                </p>
              </div>
            </a>
          ),{position})
    }
    sendData(){
        const{name,email,subject,message}=this.state;
        this.displayNotif(true,"Votre message est maintenant propulsé dans les internets !")
        emailjs.send(SERVICE_ID,TEMPLATE_ID,{from_name:name,from_email:email,from_subject:subject,from_message:message},USER_ID)
            .then(response=>{
                console.log("good", response.status,response.text)
                this.displayNotif(true,"Parfait ! Votre message est bien arrivé à destination !")
                this.setState({isSend:true,isError:false});
            },error=>{
                this.displayNotif(false,"Désolé, nous avons rencontré un problème dans l'envoi de ce message, veuillez réessayer.");
                this.setState({isSend:true,isError:false});
                console.log("the BAD",error)
            });
    }    
    render(){
        const {titre,incipit,id}=this.props;
        const toAnimate=this.props.id===this.props.idPartEncours
        const className=this.props.id===this.props.idPartEncours ?'h2-main h2-in title-reas' : 'h2-main h2-out title-reas';
        const { validated } = this.state;      
        return(
            
            <Container id={id}fluid="true" ref={this[id]} className="blue-container">
                <Row fluid="true" className="reas-header">
                    <Title className={className} pose={toAnimate} content={titre}/>
                </Row>
                <Row fluid="true" className="form-cont">
                    {/*<Col dangerouslySetInnerHTML={parseHtml(content)} />*/}
                    <Incipit pose={toAnimate}>
                        <Col >
                            <p dangerouslySetInnerHTML={parseHtml(parseWordpressContent(incipit))} />
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={e => this.handleSubmit(e)}
                            >
                            <Form.Group controlId="validationCustom01" className='form-contact'>
                                <Form.Label>Votre nom :</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="name"
                                    placeholder="Prénom Nom"
                                    defaultValue=""
                                    onChange={e=>this.handleChange(e)}
                                />
                                <Form.Control.Feedback type="invalid">Veuillez renseigner votre nom !</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="validationCustomUsername" className='form-contact'>
                                <Form.Label>Votre email :</Form.Label>
                                <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="email@domaine.com"
                                    aria-describedby="inputGroupPrepend"
                                    onChange={e=>this.handleChange(e)}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Veuillez renseigner votre e-mail
                                </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="validationsujet" className='form-contact'>
                                <Form.Label>Quel est l'objet de votre demande ?</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="subject"
                                    placeholder="ex : prise de contact, demande de renseignements"
                                    onChange={e=>this.handleChange(e)}
                                    defaultValue=""
                                />
                                <Form.Control.Feedback type="invalid">Veuillez saisir un sujet !</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1" className='form-contact'>
                                <Form.Label>Votre message : </Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows="8" 
                                    name="message"
                                    onChange={e=>this.handleChange(e)}
                                />
                                </Form.Group>
                                <Form.Group className='form-contact'>
                                <Button className="wpcf7-submit" type="submit">ENVOYER</Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Incipit>
                 </Row>
            </Container>
           
        )
    }
}

export default Contact;