import React,{Component} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Title from "./animated/Title"
import Incipit from "./animated/Incipit"
//import {parseWordpressContent,parseHtml} from"../utils/utils.js";
import "./reas.scss";

class Clients extends Component{
    constructor(props){
        super(props);
        this[this.props.id]=React.createRef();
    }
    componentDidMount(){
    }
    render(){
        const {id,titre,content}=this.props
        console.log("content client",content)
        const logos=content[0].gallery;
        const toAnimate=this.props.id===this.props.idPartEncours
        const className=this.props.id===this.props.idPartEncours ?'h2-main h2-in title-reas' : 'h2-main h2-out title-reas'      
        return(
        <Container id={id}fluid="true" ref={this[id]} className="blue-container">
            <Row fluid="true" className="reas-header">
                <Title className={className} pose={toAnimate} content={titre}/>
            </Row>
            <Row fluid="true" className="reas-header">
                <Incipit className="reas-incipit-cont" pose={toAnimate}>
                    <p className="client-incipit">{content[0].content}</p>
                </Incipit>
            </Row>
            <Row fluid="true" className="cont-logos">
                {
                    logos.map((image,id)=>{
                        return(
                            <Col xs={6} md={3} key={id} className='align-items-center'>
                                <img src={image.url} alt={`logo du client ${id}`} />
                            </Col>
                        )

                    })    
                }
            </Row>
        </Container>
        )

    }
}
export default Clients;