import React from 'react';
import posed from 'react-pose';

const Inner=posed.div(
    {
        visible:{
            opacity:1,
            x:0,
            transition:{
                duration:800,
                delay:200,
            }
        },
        hidden:{
            opacity:0,
            x:-300,
            transition:{
                duration:600,
                delay:0
            }
        }
    }
)
const Incipit=props=>(
    <Inner className={props.className} pose={props.pose?'visible':'hidden'}>
        {props.children}
    </Inner>
)
export default Incipit;