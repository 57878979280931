import React from 'react';
import {Component} from 'react';
import logo from '../images/logo.svg';
import {Navbar, Nav, Row} from 'react-bootstrap';
import './header.scss';

class Header extends Component{
    constructor(props){
        super(props);
        this.clickMenu=this.clickMenu.bind(this);
        this.clickItemMenus=this.clickItemMenus.bind(this);
        this.state={
            openedMenu:false,
            activeItem:this.props.menu[0].id
        }
    }
    componentWillMount(){}
    componentWillReceiveProps(nextProps){
        //console.log("Props !!!",nextProps.activePart)
        if(this.props.activePart !=nextProps.activePart){
            this.setState({activeItem:nextProps.activePart})
        }
        
    }
    clickItemMenus(event){
        if(event.currentTarget.dataset.id!==undefined){
            this.setState({activeItem:Number(event.currentTarget.dataset.id),openedMenu:false})
            this.props.navFunction(this.lookForSection(Number(event.currentTarget.dataset.id)))

        }
    }
    lookForSection(pId){
        const {menu}=this.props;

       return menu.find(item=>{
            return item.id===pId
        }).id
    }
    displayMenu(items){
        return(
            items.map(item=>{
                const className=this.state.activeItem===item.id ? "header-items-active" : "header-items";
                return(
                    <Nav.Link key={item.id} data-id={item.id} className={className} onClick={this.clickItemMenus}>{item.name}</Nav.Link>  
                    //<li key={item.id} className="header-items">{item.name}</li>
                )
            })
        )

    }
    clickMenu(event){
        //console.log("menus",this)
        const newOpened=!this.state.openedMenu;
        this.setState({openedMenu:newOpened})
        //console.log("event sur menu : ",event.currentTarget," ",this.state.openedMenu);
    }
    render(){
        const {title,menu,style}=this.props;
        //console.log("style ",this.props.style)
        const menuClasses=this.state.openedMenu ? "de-mots-items" : "de-mots-items hidden-menu"
        const menuBurger=this.state.openedMenu ? "menu-burger-opened" : "menu-burger"
        return(
            <Row>
                <Navbar fixed="top" expand="md" className={style} expanded={this.state.openedMenu} collapseOnSelect>
                    <Navbar.Brand>
                        <img 
                            src={logo}
                            alt="Logo"
                            className="logo-header"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.clickMenu} className={menuBurger}/>
                    <Navbar.Collapse id="basic-navbar-nav" className={`header-menu ${menuClasses} ${this.state.openedMenu?'':'collapsed'}`}>
                            <Nav className={`header-baseline`} >
                                
                                {this.displayMenu(menu)}
                            </Nav>
                    </Navbar.Collapse>
                    {/*<p>{title}</p>*/}
                </Navbar>
            </Row>
        )
    }

}
export default Header;