import React,{Component} from 'react';
import { Container,Row,Col, Card } from 'react-bootstrap';
import "./approche.scss"
import {parseWordpressContent,parseHtml} from "../utils/utils.js";
import posed from 'react-pose';
import Title from './animated/Title';
import Incipit from './animated/Incipit'


const CardAnim=posed(Card.Title)(
    {
        'visible':{opacity:1,scale:1,},
        'hidden': {opacity:0,scale:0.5}
    }
)
const Label=posed.div(
    {
        'visible':{opacity:1,x:0,visibility:0},
        'hidden': {opacity:0,x:-100,visibility:1}
    }
)

class Approche extends Component{
    constructor(props){
        super(props)
        this[this.props.id]=React.createRef();
        this.state={partEnCours:0,width: window.innerWidth}
        this.selectCard=this.selectCard.bind(this);
        this.cardOver=this.cardOver.bind(this);
        this.cardOut=this.cardOut.bind(this);
    }
    componentDidUpdate(){
        if(this.props.id!==this.props.idPartEncours){
            if(this.state.partEnCours!==0){
                this.setState({partEnCours:0})
            }
        }
    }
    selectCard(e){
        console.log("key--> ",e.currentTarget.id)
        e.stopPropagation();
        if(this.state.partEnCours!==e.currentTarget.id)
            this.setState({partEnCours:e.currentTarget.id})
        else
            this.setState({partEnCours:0})
    }
    cardOver(e){
        e.stopPropagation();
        this.setState({partEnCours:e.currentTarget.id})
    }
    cardOut(e){
        e.stopPropagation();
        this.setState({partEnCours:0});
    }
    displayContent(pContent,pIndice,pPose){
       return(
           <Label pose={pPose} className={`card-over-${pIndice}`}>
              {pContent.map((item,indice)=>{
                  //console.log(item)
                  return(
                    <p className='card-p'key={indice} dangerouslySetInnerHTML={parseHtml(item)} />
                  )
              })
              }
           </Label>
       )
    }
    render(){
        const {titre,content,incipit,id}=this.props;
        const{partEnCours,width}=this.state;
        const isMobile=width<=780;
        const toAnimate=(this.props.id===this.props.idPartEncours)
        const className=(this.props.id===this.props.idPartEncours) ? 'h2-main h2-in' : 'h2-main h2-out'
        return(
            <Container ref={this[id]} fluid="true" className="cont-approche">
                <Title 
                    className={className}
                    pose={toAnimate}
                    content={titre}
                    alpha={false}
                />
                <Row>
                    <Incipit className="dumb" pose={toAnimate}>
                        <p className="app-incipit" dangerouslySetInnerHTML={parseHtml(incipit)} />
                    </Incipit>
                </Row>
                <Row className="app-row">
                    {
                        content.map((item,indice)=>{
                        const currentId=`card-${item.id}`
                        const classVisible=(partEnCours===currentId)&&toAnimate ?'visible':'hidden'
                        //console.log ("--------");
                        return(
                            <Col sd={3} key={item.id} id={`card-${item.id}`}  onMouseOver={isMobile ?()=>{return false} : this.cardOver} onMouseOut={isMobile ?()=>{return false} :this.cardOut} onClick={this.selectCard}>
                                <Card id={`card-${item.id}`} className={`app-card item-${indice}`}>
                                    <Card.Img src={item.asset.url} className="card-img"/>
                                    <Card.Body className="app-card-label card-img-overlay">
                                        <CardAnim pose={classVisible}>
                                            <div className="fond-card" />
                                            <h3>{item.title}</h3> 
                                        </CardAnim>
                                    </Card.Body>
                                </Card>
                                {this.displayContent(item.content,indice,classVisible)}
                            </Col>
                        )
                        
                    }
                    )
                    
                    }
                </Row>
            </Container>

        )
    }
}
export default Approche;